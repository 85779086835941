// Generated by Framer (d351ec1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["lgUWcVyjx", "QAlh07WAn", "UCA8u27mY", "RUrszoEB2"];

const serializationHash = "framer-CfbwE"

const variantClassNames = {lgUWcVyjx: "framer-v-1y7qzj4", QAlh07WAn: "framer-v-kc4yjj", RUrszoEB2: "framer-v-1g0pza7", UCA8u27mY: "framer-v-npl8xa"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"L - 120px": "lgUWcVyjx", "M - 80px": "QAlh07WAn", "S - 64px": "UCA8u27mY", "XS - 40px": "RUrszoEB2"}

const getProps = ({height, id, visible, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "lgUWcVyjx", VLJ7OkvVi: visible ?? props.VLJ7OkvVi ?? true} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;visible?: boolean; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, VLJ7OkvVi, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "lgUWcVyjx", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}>{VLJ7OkvVi && (<Transition value={transition}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1y7qzj4", className, classNames)} data-framer-name={"L - 120px"} layoutDependency={layoutDependency} layoutId={"lgUWcVyjx"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({QAlh07WAn: {"data-framer-name": "M - 80px"}, RUrszoEB2: {"data-framer-name": "XS - 40px"}, UCA8u27mY: {"data-framer-name": "S - 64px"}}, baseVariant, gestureVariant)}/></Transition>)}</Variants>
</LayoutGroup>)

});

const css = [".framer-CfbwE[data-border=\"true\"]::after, .framer-CfbwE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-CfbwE.framer-oueb50, .framer-CfbwE .framer-oueb50 { display: block; }", ".framer-CfbwE.framer-1y7qzj4 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: 120px; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: 120px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-CfbwE.framer-1y7qzj4 { gap: 0px; } .framer-CfbwE.framer-1y7qzj4 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-CfbwE.framer-1y7qzj4 > :first-child { margin-top: 0px; } .framer-CfbwE.framer-1y7qzj4 > :last-child { margin-bottom: 0px; } }", ".framer-CfbwE.framer-v-kc4yjj.framer-1y7qzj4 { height: 80px; width: 80px; }", ".framer-CfbwE.framer-v-npl8xa.framer-1y7qzj4 { height: 64px; width: 64px; }", ".framer-CfbwE.framer-v-1g0pza7.framer-1y7qzj4 { height: 40px; width: 40px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 120
 * @framerIntrinsicWidth 120
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"QAlh07WAn":{"layout":["fixed","fixed"]},"UCA8u27mY":{"layout":["fixed","fixed"]},"RUrszoEB2":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"VLJ7OkvVi":"visible"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerM4cLBmYn5: React.ComponentType<Props> = withCSS(Component, css, "framer-CfbwE") as typeof Component;
export default FramerM4cLBmYn5;

FramerM4cLBmYn5.displayName = "V Block";

FramerM4cLBmYn5.defaultProps = {height: 120, width: 120};

addPropertyControls(FramerM4cLBmYn5, {variant: {options: ["lgUWcVyjx", "QAlh07WAn", "UCA8u27mY", "RUrszoEB2"], optionTitles: ["L - 120px", "M - 80px", "S - 64px", "XS - 40px"], title: "Variant", type: ControlType.Enum}, VLJ7OkvVi: {defaultValue: true, title: "Visible", type: ControlType.Boolean}} as any)

addFonts(FramerM4cLBmYn5, [])